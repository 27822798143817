import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonTabs, cn, Gallery, NavArrows, useMedias } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import CardInView from '@Components/CardInView';
import styles from './GallerySection.module.css';

import type { ReactElement, HTMLAttributes } from 'react';
import type { IGalleryRef, SwiperClass, Tab } from '@divlab/divanui';
import type { CardInViewProps } from '@Components/CardInView';

export interface GallerySectionProps<T, N extends keyof T>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  cnGallery?: string; // (--columns-count) и (--space-between)
  cnSlide?: string; // (--width)
  cnTitle?: string;
  cnDescription?: string;

  flexible?: boolean; // по умолчанию 4 колонки
  defaultSizes?: boolean; // по умолчанию 4 колонки -> 3 колонки (desktop-m) -> 310px (mobile-m) -> 260px (mobile-xxs)
  defaultGap?: boolean; // по умолчанию 30px -> 20px (desktop)
  fullWidth?: boolean; // слайды во всю ширину контейнера
  inContainer?: boolean;
  enlargedText?: boolean; // увеличенный заголовок и описание

  title?: string | ReactElement;
  description?: string | ReactElement;
  titleTag?: TitleTag;
  slides?: T[];
  fieldTab?: N extends keyof T ? (T[N] extends string ? N : never) : never; // Для табов можно выбрать любой строковый проп в T
  alignment?: 'top' | 'center' | 'bottom';
  scrollable?: boolean; // Отображение элементов в слайдере или в сетке
  attachment?: ReactElement; // Вложение после description

  pagination?: boolean;
  buttons?: boolean;
  scrollbar?: boolean;

  renderSlide(slide: T, index: number): ReactElement;
  analytics?(slide: T, index: number): CardInViewProps;
}

export type TitleTag = 'h1' | 'h2' | 'div';
/**
 * Гибкая галерея в виде слайдера или сетки с элементами управления, текстом и аналитикой.
 * Управлять количеством и размерами слайдов с помощью CSS переменных или пропсов.
 *
 * @param cnGallery - Количество колонок для слайдера или сетки. Переменные (--columns-count) и (--space-between).
 * @param cnSlide - Ширина слайда для слайдера. Переменную (--width) можно вычислять как в px, так и в % от ширины контейнера
 *
 * @param flexible - Задает гибкие размеры слайдов.
 * @param fullWidth - Задает слайды во всю ширину контейнера.
 * @param defaultGap - Задает стандартные отступы между слайдами.
 * @param defaultSizes - Задает стандартные размеры и слайдов и отступов.
 */
const GallerySection = <T, N extends keyof T>(props: GallerySectionProps<T, N>): ReactElement => {
  const { isDesktop } = useMedias();
  const {
    className,
    cnGallery,
    cnSlide,
    cnTitle,
    cnDescription,
    flexible,
    defaultSizes,
    defaultGap,
    fullWidth,
    inContainer = true,
    enlargedText,
    title,
    description,
    fieldTab,
    titleTag = 'h2',
    slides,
    renderSlide,
    alignment,
    scrollable = true,
    attachment,
    pagination,
    buttons = !isDesktop,
    scrollbar = true,
    analytics,
    ...restProps
  } = props;
  const Title = titleTag;
  const { t } = useTranslation();

  const galleryRef = useRef<IGalleryRef>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const [selectedTab, setSelectedTab] = useState('all');

  const tabs = useMemo(() => {
    if (!fieldTab) return [];

    const defaultTab = { id: 'all', label: t('ui.all-categories') };
    const uniqueLabels = Array.from(new Set(slides.map((slide) => slide[fieldTab] as string)));
    const uniqueTabs = uniqueLabels.map((tab) => ({ id: tab, label: tab }));

    return [defaultTab, ...uniqueTabs];
  }, [fieldTab, slides, t]);

  const filteredSlides = useMemo(() => {
    if (selectedTab === 'all') return slides;

    return slides.filter((slide) => slide[fieldTab] === selectedTab);
  }, [fieldTab, selectedTab, slides]);

  const hasSlidesData = useMemo(() => {
    return filteredSlides?.length > 0 && renderSlide;
  }, [filteredSlides?.length, renderSlide]);

  const handleChangeTab = useCallback((_, tab: Tab) => setSelectedTab(tab.id), []);

  const handlePrev = useCallback(() => galleryRef.current?.slidePrev(), []);
  const handleNext = useCallback(() => galleryRef.current?.slideNext(), []);

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);

  useEffect(() => {
    const swiper = galleryRef.current?.swiper;

    if (filteredSlides && swiper) {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  }, [filteredSlides]);

  return (
    <div
      className={cn(styles.wrapper, className, { [styles.inContainer]: inContainer })}
      {...restProps}
    >
      {(title || description || tabs?.length > 2 || (buttons && hasSlidesData && scrollable)) && (
        <div className={cn(styles.container, { [styles.withTabs]: tabs?.length })}>
          <div className={styles.textContainer}>
            {title && (
              <Title className={cn(styles.title, cnTitle, { [styles.enlarged]: enlargedText })}>
                {title}
              </Title>
            )}
            {description && (
              <div
                className={cn(styles.description, cnDescription, {
                  [styles.enlarged]: enlargedText,
                })}
              >
                {description}
              </div>
            )}
            {attachment}
          </div>

          <div className={styles.nav}>
            {tabs?.length > 2 && (
              <ButtonTabs
                className={styles.tabs}
                scrollable
                defaultValue={selectedTab}
                tabs={tabs}
                onChangeTab={handleChangeTab}
              />
            )}

            {buttons && hasSlidesData && scrollable && (
              <NavArrows
                className={styles.arrows}
                prevDisabled={isBeginning}
                nextDisabled={isEnd}
                onPrev={handlePrev}
                onNext={handleNext}
              />
            )}
          </div>
        </div>
      )}

      {/* Слайдер */}
      {hasSlidesData && scrollable && (
        <Gallery
          className={cn(styles.slider, cnGallery, {
            [styles.defaultSizes]: defaultSizes,
            [styles.defaultGap]: defaultGap,
          })}
          cnGallery={styles.gallery}
          ref={galleryRef}
          cnSlide={cn(styles.sliderSlide, cnSlide, {
            [styles.defaultSizesSliderSlide]: defaultSizes,
            [styles.flexibleSliderSlide]: flexible,
            [styles.top]: alignment === 'top',
            [styles.center]: alignment === 'center',
            [styles.bottom]: alignment === 'bottom',
            [styles.fullWidth]: fullWidth,
          })}
          scrollbar={scrollbar}
          pagination={pagination}
          onSlideChange={handleSlideChange}
        >
          {filteredSlides.map((slide, index) => {
            const elem = renderSlide(slide, index);
            const definedAnalytics =
              typeof analytics === 'function' ? analytics(slide, index) : analytics;

            return definedAnalytics ? (
              <CardInView key={definedAnalytics.id} {...definedAnalytics}>
                {elem}
              </CardInView>
            ) : (
              <Fragment key={elem.key || index}>{elem}</Fragment>
            );
          })}
        </Gallery>
      )}

      {/* Сетка */}
      {hasSlidesData && !scrollable && (
        <div
          className={cn(styles.grid, cnGallery, {
            [styles.defaultSizes]: defaultSizes,
            [styles.defaultGap]: defaultGap,
          })}
        >
          {filteredSlides.map((slide, index) => {
            const elem = renderSlide(slide, index);
            const definedAnalytics =
              typeof analytics === 'function' ? analytics(slide, index) : analytics;

            return (
              <div className={cn(styles.gridSlide, cnSlide)} key={definedAnalytics?.id || index}>
                {definedAnalytics ? <CardInView {...definedAnalytics}>{elem}</CardInView> : elem}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GallerySection;
